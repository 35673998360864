pagination = {}

pagination.arrowClick = function (el) {
  var myCat = $("." + fWrapper.filter.findCat())

  $(".wrapper--project_title").velocity("scroll")

  if ($(el).hasClass("previous")) {
    if (!$(myCat).find(".pagination_item.active").is(":first-child")) {
      $(myCat)
        .find(".pagination_item.active")
        .removeClass("active")
        .prev(".pagination_item")
        .addClass("active")
      $(myCat)
        .find(".pagination-section")
        .scrollLeft($(myCat).find(".pagination-section").scrollLeft() - 20)
    }
  } else {
    if (!$(myCat).find(".pagination_item.active").is(":last-child")) {
      $(myCat)
        .find(".pagination_item.active")
        .removeClass("active")
        .next(".pagination_item")
        .addClass("active")
      $(myCat)
        .find(".pagination-section")
        .scrollLeft($(myCat).find(".pagination-section").scrollLeft() + 20)
    }
  }
}

pagination.itemClick = function (e) {
  $(".wrapper--project_title").velocity("scroll")

  var currActive = $(".pagination_item.active"),
    currActiveValue = parseInt($(".pagination_item.active").html(), 10)
  ;(selected = $(e.target)), (selectedValue = parseInt($(e).html(), 10))

  $(selected).addClass("active")
  $(currActive).removeClass("active")

  if (selectedValue >= currActiveValue) {
    $(".pagination-section").scrollLeft(
      $(".pagination-section").scrollLeft() + 20 * (selectedValue - 1)
    )
  } else {
    $(".pagination-section").scrollLeft(
      $(".pagination-section").scrollLeft() - 20 * (selectedValue - 1)
    )
  }
}

pagination.bindListeners = function () {
  $(".pagination_arrow").click(function (e) {
    e.preventDefault()
    pagination.arrowClick(this)
    fWrapper.filter.postParams.bind(fWrapper.filter)()
  })

  $(".pagination_item").click(function (e) {
    e.preventDefault()
    pagination.itemClick(e)
    fWrapper.filter.postParams.bind(fWrapper.filter)()
  })
}

$(document).on("turbolinks:load", function () {
  pagination.bindListeners()
})
