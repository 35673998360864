$(document).on("turbolinks:load", function() {
  var siteLocation_select = document.getElementById(
    "form_submission_answer_15"
  );
  var containmentClass_select = document.getElementById(
    "form_submission_answer_21"
  );

  var isTheSoilStockpiled_select = document.getElementById(
    "form_submission_answer_23"
  );
  var hasSiteAssessmentBeenCarriedOut_select = document.getElementById(
    "form_submission_answer_24"
  );
  var areAnalyticalResultsAvailable_select = document.getElementById(
    "form_submission_answer_25"
  );

  function disabledSelectOption(parentEl) {
    if (parentEl !== null) {
      var children = parentEl.children;
      for (var i = 0; i < children.length; i++) {
        if (children[i].value === "-- Please select --") {
          children[i].setAttribute("disabled", true);
        }
      }
    }
  }
  disabledSelectOption(siteLocation_select);
  disabledSelectOption(containmentClass_select);
  disabledSelectOption(isTheSoilStockpiled_select);
  disabledSelectOption(hasSiteAssessmentBeenCarriedOut_select);
  disabledSelectOption(areAnalyticalResultsAvailable_select);
});
