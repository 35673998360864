/* eslint-disable no-undef */
$(document).on("turbolinks:load", function () {
  // https://www.ventia.com/projects/Venture-Smart
  // Adds ID's to the CTA and Submit button for GA tracing.
  var vs_cta = ""
  var vs_formModal = ""
  var vs_buttonSubmit = ""
  var vs_path = "/projects/Venture-Smart"

  if (location.pathname === vs_path) {
    vs_cta = $(".sidebar-cta__button")
    vs_formModal = $(".form-modal")
    vs_buttonSubmit = $("button#enquiry_submit")

    if (vs_cta) {
      vs_cta.attr("id", "Venture_ContactUs_Begin")

      if (vs_formModal) {
        vs_buttonSubmit.attr("id", "enquiry_submit Venture_ContactUs_Submit")
      }
    } else {
      return
    }
  }

  if (typeof gtag !== "undefined") {
    var path = location.pathname + location.search
    gtag("set", "page", path)

    //Track if a users clicks 'Contact Us' CTA on-page
    $("#Venture_ContactUs_Begin").on("click", function () {
      gtag(
        "event",
        "Buttons",
        {
          name: "ventureSmartCtaClick",
          value: "Venture_ContactUs_Begin",
          event_category: "callToAction",
          event_label: "Venture_ContactUs_Begin",
        },
        {
          hitCallback: function () {},
        }
      )
    })
  }
})
