import Swiper, { Navigation } from "swiper";
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/navigation/navigation.min.css';

Swiper.use([Navigation]);
var linkedInSwiper;

$(document).on("turbolinks:load", function () {
  linkedInSwiper = new Swiper(".linkedin-list", {
    slidesPerView: "auto",
    loop: false,
    navigation: {
      nextEl: ".linkedin-next",
      prevEl: ".linkedin-prev"
    },
    observer: true,
    observeParents: true
  });
});
