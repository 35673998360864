$(document).on('turbolinks:load', function () {
  $('.info-banner__selector').click(function(e){
    handleInfoBannerSelect($(e.target).data('section-selector-index'))
  })

  $('.info-banner').on('mouseover', function(){
    $(this).removeClass('default-state')
  })

  $('.info-banner').on('mouseleave', function(){
    $(this).addClass('default-state')
  })

  $('.info-banner__content').on('mouseover', function(e) {
    handleInfoBannerSelect($(e.target).data('hover-index'))
  })

  $('.info-banner__content *').on('mouseover', function(e) {
    e.stopPropagation()
  })

  function handleInfoBannerSelect(currentIndex) {

    $('.info-banner__section.section-active').removeClass('section-active')
    $('.info-banner__selector.selector-active').removeClass('selector-active')

    $('.info-banner__section[data-section-index=' + currentIndex + ']').addClass('section-active')
    $('.info-banner__selector[data-section-selector-index=' + currentIndex + ']').addClass('selector-active')

    if(window.innerWidth <= 1023){
      setInfoBannerHeight()
    }
  }

  function setInfoBannerHeight() {
    var contentHeight = 0
    $('.info-banner__section.section-active').children().each(function(){
      contentHeight += $(this).outerHeight()
    })
    $('.info-banner').height(contentHeight)
    $('.info-banner__section.section-active').height(contentHeight)
  }

  handleInfoBannerSelect(0)

  $(".info-banner__content").click(function() {
    window.location = $(this).find(".info-banner__link").attr("href")
  })

  if(window.innerWidth <= 1023){
    var hasBeenVisible = false

    function checkIsVisible() {
      if($('.info-banner').length && $('.info-banner').isOnScreen()){
        setInfoBannerHeight()
        hasBeenVisible = true
        window.removeEventListener('scroll', handleScroll)
      }
    }

    function handleScroll() {
      if(!hasBeenVisible){
        checkIsVisible()
      }
    }

    window.addEventListener('scroll', handleScroll)
  }

});

$.fn.isOnScreen = function(){
    
    var win = $(window);
    
    var viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();
    
    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();
    
    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    
};
