import "core-js/stable"
import "regenerator-runtime/runtime"

require("turbolinks").start()
require("@rails/ujs").start();

global.$ = require("jquery")
require("jquery-ui")


// LEGACY JS
import "../application/javascripts/index";

// STYLES
import "../application/stylesheets/application"
