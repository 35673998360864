$(document).on("turbolinks:load", function () {
  // Allowing multi-form pages
  $(".form_submission_wrapper form").each(function () {
    bindRemoteFormHandler($(this))
  })

  $(".enquiry__latest-news").hide()
  $(".form-submit__loading-indicator").hide()
})

function resetCaptchas() {
  var count = 0

  $(".g-recaptcha").each(function () {
    grecaptcha.reset(count)
    count++
  })
}

function bindRemoteFormHandler($form) {
  var model_name = $form.attr("id")
    .replace('new_', '')  // remove "new_" from eg "new_form_submission"
    .split("-")[0] // remove id from multi form pages, eg "new_form_submission-id"
  $form.type = model_name

  $form.on("submit", function () {
    $form.submit.disable()
    $("#enquiry-submit").addClass("submitting")
    $(".form-submit__loading-indicator", $form).show()
  })

  $form.on("ajax:success", function () {
    $form.onSuccess()
  })

  $form.on("ajax:error", function (event) {
    resetCaptchas()

    const errors = event.detail[0]

    $.each(errors, function (key, value) {
      $form.addFieldError(key, value)
    })

    $form.showFormError()
    $form.submit.enable()
  })

  $form.submit = {
    button: $form.find("button:submit") || $("input[type=submit]", this),
    originalButtonText:
      $form.find("button:submit").text() ||
      $("input[type=submit]", this).attr("value"),
    disable: function () {
      this.button.prop("disabled", true)
      this.button.prop("value", "In Progress")
      this.button.children("span").text("In Progress")
    },
    enable: function () {
      var button = this.button
      var originalText = this.originalButtonText
      //add a little time to prevent double click
      setTimeout(function () {
        button.prop("disabled", false)
        button.children("span").text(originalText)
        button.prop("value", originalText)
      }, 200)
    },
  }

  $form.addFieldError = function (eField, message) {
    const fieldId = `${$form.type}_${eField}`
    const field = $(`#${fieldId}`, $form)

    if ($form.type === "talent_agreement") {
      field.closest("div").addClass("error")
    } else {
      field.closest("li").addClass("error")
    }

    field.velocity("callout.shake")

    $(".form-submit__loading-indicator", $form).hide()
    $(".button", $form).show()
  }

  $form.removeError = function (field) {
    $(field).closest("li").removeClass("error")
  }

  //EVENT LISTENERS
  $form.find("input, select, textarea", $form).on("focus", function () {
    $form.removeError(this)
  })

  $form.onSuccess = function () {
    const $successMessage = $(".success-msg", $form)

    // Hide elements
    $(".error-msg", $form).hide()
    $(".form-submit__loading-indicator", $form).hide()
    $(".button", $form).hide()
    $("#type-of-enquiry", $form).hide()
    $(".form-list", $form).hide()
    $(".enquiry__content", $form).hide()
    $(".form-list__item .button", $form).hide()

    // Show elements
    $(".enquiry__latest-news").show()
    $successMessage.show()

    $("html, body").animate({ scrollTop: $successMessage.offset().top - 150 })

    $form.submit.enable()

    if (typeof ga !== "undefined") {
      ga("send", "event", "Buttons", "Click", "Venture_ContactUs_Submit", {
        hitCallback: function () {},
      })
    }
  }

  $form.showFormError = function () {
    const $errorMessage = $(".error-msg", $form)

    if ($(".form-modal").length > 0) {
      $(".form-modal")[0].scrollTop = 0
    }

    $(".success-msg", $form).hide()
    $errorMessage.show()
    $("html, body").animate({ scrollTop: $errorMessage.offset().top - 150 })
  }

  var preview = document.getElementById("file-upload-preview-img")

  function removeLastInsertedImgs() {
    // console.log(preview.childNodes.length)
    if (preview.childNodes.length === 1) {
      preview.childNodes[0].remove()
    }
  }

  function injectImg(file) {
    var img = createImgNode(file)
    readFileIntoImg(file, img)
  }

  function createImgNode(file) {
    var img = document.createElement("img")
    img.classList.add("obj")
    img.file = file

    preview.appendChild(img)
    return img
  }

  function readFileIntoImg(file, img) {
    var reader = new FileReader()
    reader.onload = function (e) {
      img.src = e.target.result
    }
    reader.readAsDataURL(file)
  }

  $("#show-file").on("change", function (e) {
    removeLastInsertedImgs()
    if (e.currentTarget.files) {
      var length = e.currentTarget.files.length
      for (var i = 0; i < length; i++) {
        injectImg(e.currentTarget.files[i])
      }
    } else return
  })
}
