var share = {},
    isMobile,
    isTablet,
    isNetbook;


share.open = function() {
  $(".social_share-opener").toggleClass("opened");
  $(".social_share-icon").velocity("transition.slideLeftIn", {
    duration: 300,
    stagger: 150,
    display: "flex"
  })
};

share.close = function() {
  $(".social_share-icon").velocity("transition.slideLeftOut", {
    duration: 300,
    stagger: 150,
    backwards: true,
    complete: function() {
      $(".social_share-opener").toggleClass("opened");
    }
  })
};

share.reinit = function() {
  __sharethis__.initialize();
  __sharethis__.href = window.location.href;
};

$(document).on('turbolinks:load', function () {

  isMobile = $(window).width() < 768;
  isTablet = $(window).width() >= 768 && $(window).width() < 1025;
  isNetbook = $(window).width() >= 1025;

  if (window.stButtons){stButtons.locateElements();}

  $(".social_share-icon").click(function(e) {
    e.preventDefault();
    window.open(e.currentTarget.href);
  })
});
