var mapStyle = [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#444444"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.neighborhood",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural.landcover",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "weight": "0.64"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "lightness": "19"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "saturation": "0"
            },
            {
                "lightness": "41"
            },
            {
                "gamma": "1.27"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit.station.bus",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            }
        ]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "23"
            },
            {
                "lightness": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#b8e7fa"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "weight": "0.01"
            },
            {
                "visibility": "off"
            }
        ]
    }
];

var isMobile,
    isTablet,
    isNetbook
    googleChecks = 0;

function getMapZoomLevel() {
  var zoom = $("#map_zoom_level").html();
  return zoom.length ? parseInt(zoom) : 14;
};

function initMap() {

  var latLngs = JSON.parse($(".map-lat-lngs").text()),
      isDef = false;

  if (!latLngs.length) {
    isDef = true;
    latLngs = [{
      "lat": parseFloat(-33.865143),
      "lng": parseFloat(151.209900)
    }];
  } else {
    for(var i = 0; i < latLngs.length; i++) {
      latLngs[i].lat = parseFloat(latLngs[i].lat);
      latLngs[i].lng = parseFloat(latLngs[i].lng);
    }
  }

  var map = new google.maps.Map(document.getElementById('map'), {
    zoom: getMapZoomLevel(),
    styles: mapStyle,
    disableDefaultUI: true
  });

  var icon = {
    url: '/images/marker.png',
    scaledSize: new google.maps.Size(65, 105)
  }

  if (!isNetbook) {
    icon = {
      url: '/images/marker.png',
      scaledSize: new google.maps.Size(43, 69)
    }
  }
  var markers = [];
  if (latLngs.length) {
    $(latLngs).each(function(i,e) {
      var marker = new google.maps.Marker({
        position: $(e)[0],
        map: map,
        icon: icon
      });
      markers.push(marker);
    });
    if (latLngs.length >= 2) {
      // MULTIPLE MARKERS ZOOM LEVEL TO FIT ALL
      var bound = new google.maps.LatLngBounds();
      for (var i in markers) {
        bound.extend(markers[i].getPosition());
      }
      map.fitBounds(bound);
      var listener = google.maps.event.addListener(map, "idle", function() {
        map.setZoom(map.getZoom() - 2);
        if (isNetbook) {
          map.panBy(0, -150);
        }
        google.maps.event.removeListener(listener);
      });
    } else if(latLngs.length == 1) {
      // SINGLE MARKER NO DEFAULT POSITION
      map.setCenter(markers[0].getPosition())
      if (isNetbook && !isDef) {
        map.panBy(0, -150);
      }
      if (isDef) {
        // SINGLE MARKER DEFAULT POSITION (AU AND NZ)
        markers[0].setVisible(false);
        $(".wrapper--map").hide();
        if (isNetbook) {
          $(".wrapper--map").removeClass("overlap-top--map").addClass("overlap-top");
          map.setZoom(4);
          map.panBy(-100, -50);
        } else {
          map.setZoom(3);
          map.panBy(-30, 0);
        }
      }
    }
  } else {
    map.setCenter(markers[0].getPosition())
    if (isNetbook) {
      map.panBy(0, -150);
    }
  }
}

function customInitMap() {
  if ($("#map").length && typeof google === 'object' && typeof google.maps === 'object') {
    initMap()
  } else {
    if (googleChecks <= 5) {
      window.setTimeout(customInitMap, 500);
      googleChecks++;
    }
  }
}

$(document).on('turbolinks:load', function () {
  isMobile = $(window).width() < 768;
  isTablet = $(window).width() >= 768 && $(window).width() < 1025;
  isNetbook = $(window).width() >= 1025;
  customInitMap();
})
