$(document).ready(function () {
  $("#search-filters input").on("change", function () {
    $(this).parents("li").find("label").toggleClass("checked")
  })

  function fireGA() {
    if (typeof ga !== "undefined") {
      var path = location.pathname + location.search
      ga("set", "page", path)
      ga("send", "pageview")
    }
  }
  if (window.location.hostname === "careers.ventia.com.au") {
    var oldURL = ""
    var currentURL = window.location.href
    function checkURLchange(currentURL) {
      if (currentURL != oldURL) {
        fireGA()
        oldURL = currentURL
      }
    }
    if (typeof intervalSet !== "undefined") {
      var intervalSet = setInterval(function () {
        checkURLchange(window.location.href)
      }, 1000)
    }
  }
})
