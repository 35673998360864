$(document).on("turbolinks:load", function () {
  /* https://github.com/madmurphy/cookies.js (GPL3) */
  !function(){function e(e,o,t,n,r,s,i){var c="";if(t)switch(t.constructor){case Number:c=t===1/0?"; expires=Fri, 31 Dec 9999 23:59:59 GMT":"; max-age="+t;break;case String:c="; expires="+t;break;case Date:c="; expires="+t.toUTCString()}return encodeURIComponent(e)+"="+encodeURIComponent(o)+c+(r?"; domain="+r:"")+(n?"; path="+n:"")+(s?"; secure":"")+(i&&"no_restriction"!==i.toString().toLowerCase()?"lax"===i.toString().toLowerCase()||1===Math.ceil(i)||!0===i?"; samesite=lax":"none"===i.toString().toLowerCase()||i<0?"; samesite=none":"; samesite=strict":"")}var o=/[\-\.\+\*]/g,t=/^(?:expires|max\-age|path|domain|secure|samesite|httponly)$/i;window.docCookies={getItem:function(e){return e&&decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*"+encodeURIComponent(e).replace(o,"\\$&")+"\\s*\\=\\s*([^;]*).*$)|^.*$"),"$1"))||null},setItem:function(o,n,r,s,i,c,a){return!(!o||t.test(o))&&(document.cookie=e(o,n,r,s,i,c,a),!0)},removeItem:function(o,t,n,r,s){return!!this.hasItem(o)&&(document.cookie=e(o,"","Thu, 01 Jan 1970 00:00:00 GMT",t,n,r,s),!0)},hasItem:function(e){return!(!e||t.test(e))&&new RegExp("(?:^|;\\s*)"+encodeURIComponent(e).replace(o,"\\$&")+"\\s*\\=").test(document.cookie)},keys:function(){for(var e=document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g,"").split(/\s*(?:\=[^;]*)?;\s*/),o=e.length,t=0;t<o;t++)e[t]=decodeURIComponent(e[t]);return e},clear:function(e,o,t,n){for(var r=this.keys(),s=r.length,i=0;i<s;i++)this.removeItem(r[i],e,o,t,n)}}}(),"undefined"!=typeof module&&void 0!==module.exports&&(module.exports=docCookies);

  var msie = window.document.documentMode <= 11
  // 2 "cookie" booleans one for initial ignore click.
  // second for the second ignore click that pops up 
  // 1 min after initial ignore (client request)
  var ignoredWarning 
  var ignoredWarning2
  var timer

  function checkWarningCookies() {
    ignoredWarning = docCookies.getItem("ignoreBrowserWarning") == "yes"
    ignoredWarning2 = docCookies.getItem("ignoreBrowserWarning2") == "yes"
  }

  checkWarningCookies()

  if(msie && !ignoredWarning && !ignoredWarning2) {
    showModal()
  }

  if(msie && ignoredWarning && !ignoredWarning2){
    timer = setInterval(timerTick, 1000)
  }

  document.addEventListener("turbolinks:before-cache", function() {
    if(timer !== undefined){
      clearInterval(timer)
    }
  });

  function timerTick() {
    var prevTick = parseInt(docCookies.getItem("ignoreBrowserWarning2"), 10)
    var cookieTime = isNaN(prevTick) ? 0 : prevTick + 1 

    docCookies.setItem("ignoreBrowserWarning2", cookieTime, 86400)

    if(cookieTime >= 60){
      clearInterval(timer)
      showModal()
    }
  }

  $(".close-outdated-browser").click(hideModal)

  function showModal() {
    $("#outdated-browser").show()
  }

  function hideModal() {
    checkWarningCookies()
    $("#outdated-browser").hide()

    if(!ignoredWarning && !ignoredWarning2){
      docCookies.setItem("ignoreBrowserWarning", "yes", 86400)
      timer = setInterval(timerTick, 1000)
    }
    
    if(ignoredWarning && !ignoredWarning2){
      docCookies.setItem("ignoreBrowserWarning", "yes", 86400)
      docCookies.setItem("ignoreBrowserWarning2", "yes", 86400)
    }
  }

})
