var isMobile, isTablet, isNetbook

$(document).on("turbolinks:load", function () {
  isMobile = $(window).width() < 768
  isTablet = $(window).width() >= 768 && $(window).width() < 1025
  isNetbook = $(window).width() >= 1025

  $(".tabs").tabs({
    show: { effect: "fade", duration: 300 },
    create: function (event, ui) {
      $("." + ui.panel[0].id).show()
      $("#" + ui.panel[0].id).show()
    },
    beforeActivate: function (event, ui) {
      $("." + ui.oldPanel[0].id).hide()
      $("#" + ui.oldPanel[0].id).hide()
      $("." + ui.newPanel[0].id).show()
      $("#" + ui.newPanel[0].id).show()
    },
  })

  if (
    !isMobile &&
    !$(".sidebar-content").is($(".wrapper--contact").find(".sidebar-content"))
  ) {
    $(".sidebar-content").stick_in_parent()
  }
})
